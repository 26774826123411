export default [
  {
    key: 'category_id',
    label: 'Kategori',
    type: 'select',
    text: 'name',
    actionSearch: 'questionCategory/search',
    required: true,
    initialOptions: true,
  },
  {
    key: 'question',
    label: 'Pertanyaan',
    required: true,
  },
  {
    key: 'answer',
    label: 'Jawaban',
    type: 'quill',
    required: true,
  },
]
